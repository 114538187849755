<template>
  <v-row no-gutters>
    <v-col md="3">
      <text-field
        v-model.trim="search"
        outlined
        label="Search Project"
        placeholder="Type to search"
        maxlength="255"
      />
    </v-col>
    <v-col md="12">
      <data-table
        v-model="selectedRows"
        class="mt-5"
        :search="search"
        :loading="loading"
        :items="members"
        :headers="headers"
        :hide-footer="true"
        :items-per-page="20"
        :server-items-length="membersMeta.total"
        :page="membersMeta.current_page"
      >
        <template v-slot:actions-menu="{ item }" class="test">
          <div class="d-flex justify-end">
            <data-table-actions>
              <text-button
                class="mr-2"
                :loading="resendLoading"
                blue-loader
                @click="resendInvite(item)"
              >
                Resend
              </text-button>
            </data-table-actions>
            <data-table-actions>
              <data-table-action-item
                icon="delete"
                @click="deleteInvite(item)"
              />
            </data-table-actions>
          </div>
        </template>
        <template v-slot:footer>
          <div v-show="members.length" class="v-data-footer">
            <base-pagination
              v-model="params.page"
              :pagination-meta="membersMeta"
              :has-pagination-pages="false"
              :has-counter-items-on-page="true"
              @change-page="changePaginationPage"
            />
          </div>
        </template>
      </data-table>
    </v-col>
    <table-checked-bar v-model="snackbar">
      <v-row no-gutters>
        <v-col>
          <secondary-button small>
            Move {{ selectedRows.length || '' }}
          </secondary-button>
        </v-col>
        <v-col>
          <secondary-button small>
            Delete {{ selectedRows.length || '' }}
          </secondary-button>
        </v-col>
        <v-col>
          <text-button
            small
            @click="resetSelectedRows"
          >
            Cancel
          </text-button>
        </v-col>
      </v-row>
    </table-checked-bar>
    <confirm-modal
      v-model="confirmStatus"
      title="Delete invite"
      @save="confirmDeleteInvite"
    >
      Are you sure you want to delete this invite?
    </confirm-modal>
  </v-row>
</template>

<script>
import TextField from '@/components/ui/TextField/TextField';
import DataTable from '@/components/collections/DataTable/DataTable';
import DataTableActions from '@/components/collections/DataTableActions/DataTableActions';
import DataTableActionItem from '@/components/collections/DataTableActions/DataTableActionItem';
import TableCheckedBar from '@/components/ui/Snackbars/TableCheckedBar';
import SecondaryButton from '@/components/ui/Buttons/SecondaryButton';
import TextButton from '@/components/ui/Buttons/TextButton';
import { MEMBERS_UNCONFIRMED } from '@/views/Member/constants';
import ConfirmModal from '@/components/collections/Dialog/ConfirmModal';
import { debounce, updateParams } from '@/helpers';
import BasePagination from '@/components/ui/Pagination/BasePagination';
import queryParamsMixin from '@/mixins/queryParamsMixin';

export default {
  name: 'Unconfirmed',
  components: {
    ConfirmModal,
    TextButton,
    SecondaryButton,
    TableCheckedBar,
    DataTableActionItem,
    DataTableActions,
    DataTable,
    TextField,
    BasePagination,
  },
  mixins: [queryParamsMixin],
  data() {
    return {
      search: null,
      members: [],
      headers: MEMBERS_UNCONFIRMED,
      snackbar: false,
      selectedRows: [],
      loading: false,
      params: {
        page: 1,
      },
      confirmStatus: false,
      resendLoading: false,
      deleteId: null,
      debouncesearch: null,
    };
  },
  computed: {
    selectedWorkspaceId() {
      return this.$store.getters.getSelectedWorkspace.id;
    },
    membersMeta() {
      return this.$store.getters.getMembersMeta;
    },
  },
  watch: {
    selectedRows: {
      deep: true,
      handler(val) {
        this.snackbar = !!val.length;
      },
    },
    search(val) {
      if (val === undefined || val === null) return;

      if (val.length > 255) {
        this.search = val.slice(0, 255);
      }

      this.useDebounce(val);
    },
    params: {
      handler(data) {
        this.setQueryParams({
          search: data.search,
          page: data.page,
        });
      },
      deep: true,
    },
  },
  created() {
    this.useDebounce = debounce((searchValue) => {
      this.handleSearch(searchValue);
    }, 600);
  },
  async mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      this.updateDataByQueryParams(this.$route.query);
      this.search = this.$route.query.search;
    }

    if (!this.$route.query?.search) {
      await this.initialLoad();
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'updateSelectedWorkspace') {
        this.initialLoad();
      }
    });
  },
  methods: {
    handleSearch(searchValue) {
      this.params = updateParams(searchValue, this.params);
      this.initialLoad();
    },
    async initialLoad() {
      this.loading = true;
      await this.$store.dispatch('GET_UNCONFIRMED_MEMBERS', {
        id: this.selectedWorkspaceId,
        params: this.params,
      })
        .then(() => {
          this.members = this.$store.getters.getMembers;
          this.loading = false;
        });
    },
    changePaginationPage(page) {
      this.params.page = page;
      this.loading = true;
      this.$store.dispatch('GET_UNCONFIRMED_MEMBERS', {
        id: this.selectedWorkspaceId,
        params: this.params,
      })
        .then(() => {
          this.members = this.$store.getters.getMembers;
          this.loading = false;
        });
    },
    resendInvite(val) {
      this.resendLoading = true;
      this.$store.dispatch('RESEND_INVITE', {
        id: this.selectedWorkspaceId,
        memberId: val.member_card_id,
      })
        .then(() => {
          this.resendLoading = false;
        });
    },
    deleteInvite(val) {
      this.confirmStatus = true;
      this.deleteId = val.member_card_id;
    },
    confirmDeleteInvite() {
      this.$store.dispatch('DELETE_MEMBERS', {
        id: this.selectedWorkspaceId,
        members: { member_card_ids: [this.deleteId] },
      }).then(() => {
        this.confirmStatus = false;
        this.initialLoad();
      });
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .custom-member {
    display: flex;
    align-items: center;
    &-img {
      width: 28px;
      height: 28px;
      border: 2px solid $white;
      border-radius: 11px;
      margin-right: 8px;
    }
  }

  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

</style>
